import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import { navigate, Link } from 'gatsby';
import Skeleton from 'react-loading-skeleton';

import CcAuth from 'components/CcAuth';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import TitleName from 'components/UI/Header/TitleName';
import { useAPI } from 'utils/api';
import { getDispatchData } from 'utils/_dispatch.js';
import { isAuthenticated } from 'utils/_auth';

const mixinAllCategoryList = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const mixinCategoryItem = css`
  flex: 0 0 calc((100% - 16px) / 3);
  height: 94px;
  border-radius: 8px;
`;

const Container = styled.div`
  display: block;
  margin: ${(props) => `${props.headerHeight}px`} auto 0;

  .skeleton-wrapper {
    ${mixinAllCategoryList}

    .skeleton {
      ${mixinCategoryItem}
    }
  }
`;

const StyledHeader = styled(Header)`
  .switch-delivery-wrapper,
  .address-wrapper {
    display: none;
  }

  .title {
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

const AllCategoryList = styled.div`
  ${mixinAllCategoryList}
`;

const CategoryItem = styled(Link)`
  ${mixinCategoryItem}
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 10px;
  cursor: pointer;
`;

const CategoryItemImage = styled.img`
  width: 64px;
  height: 64px;
`;

const CategoryItemName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #3b3516;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-width: 90px;
`;

const Content = styled.div`
  padding: 0 15px 25px;
`;

const AllCategory = () => {
  const api = useAPI();
  const dispatchData = getDispatchData();
  const storeId = dispatchData?.storeId ?? '';

  const categoryList = useSelector((state) => getters.getCategoryList(state));
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const getCategories = () => {
    api
      .getCategories(storeId)
      .then((res) => {
        store.dispatch(actions.setCategoryList(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategoriesNoToken = () => {
    api
      .getCategoriesNoToken(storeId)
      .then((res) => {
        store.dispatch(actions.setCategoryList(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;
    if (isAuthenticated()) {
      getCategories();
    } else {
      if (storeId === '') return;
      getCategoriesNoToken();
    }
  }, [ccAuthIsReady]);

  return (
    <>
      <TitleName />
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {ccAuthIsReady && (
        <Container headerHeight={headerHeight}>
          <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
            <StyledHeader title="商品分類" />
          </HeaderWrapper>
          <Content>
            {categoryList ? (
              <AllCategoryList>
                {categoryList?.map((item) => {
                  const sub = item.sub[0];
                  return (
                    <CategoryItem to={`/category?cid=${sub.id}`}>
                      <CategoryItemImage src={item.image} />
                      <CategoryItemName>{item.name}</CategoryItemName>
                    </CategoryItem>
                  );
                })}
              </AllCategoryList>
            ) : (
              <Skeleton
                containerClassName="skeleton-wrapper"
                className="skeleton"
                count={30}
                inline
              />
            )}
          </Content>
        </Container>
      )}
    </>
  );
};

export default AllCategory;
